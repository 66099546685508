import React, { Component } from "react";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Navbar,
  Nav,
  FormControl,
  Dropdown,
  Badge,
  Button,
} from "react-bootstrap";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { LinkContainer } from "react-router-bootstrap";
import layoutHelpers from "./helpers";
import PartnerNavSelector from "../../components/common/partners/PartnerNavSelector";
import { setLoggedIn } from "../../utils/auth/authhelpers";
import { getCurrentPartnerId } from "../../utils/auth/partnerhelpers";
import UpgradeSubscriptionModal from "../../components/authentication/UpgradeSubscriptionModal";
const env = runtimeEnv();

class LayoutNavbar extends Component {
  constructor(props) {
    super(props);
    this.isRTL = document.documentElement.getAttribute("dir") === "rtl";

    this.closeQuickAdd = this.closeQuickAdd.bind(this);

    this.state = {
      showQuickAdd: false,
      showSubscriptionModal: false,
      searchTerm: null,
    };
  }

  closeQuickAdd() {
    this.setState({ showQuickAdd: false });
  }

  toggleSidenav(e) {
    e.preventDefault();
    layoutHelpers.toggleCollapsed();
  }

  async signOut() {
    setLoggedIn(false);
    window.location = "/login?r=" + window.location.pathname;
    var result = await firebase.auth().signOut();
  }

  render() {
    return (
      <>
        <Navbar
          bg={this.props.navbarBg}
          expand="lg"
          className="layout-navbar align-items-lg-center container-p-x"
        >
          {/* Sidenav toggle (see src/demo.css) */}
          {this.props.sidenavToggle && (
            <Nav className="layout-sidenav-toggle d-lg-none align-items-lg-center mr-3">
              <Nav.Item
                as="a"
                className="nav-link px-0 mr-lg-4"
                href="#toggle"
                onClick={this.toggleSidenav}
              >
                <i className="ion ion-md-menu text-large align-middle"></i>
              </Nav.Item>
            </Nav>
          )}

          {/* Brand demo (see src/demo.css) */}
          <Navbar.Brand
            as={NavLink}
            to="/"
            className="app-brand demo d-lg-none py-0"
          >
            <img
              src={this.props.claims.logo_url}
              alt="ProStruct360"
              className="app-brand-logo demo"
            />
          </Navbar.Brand>

          <Navbar.Toggle>
            <i className="fas fa-chevron-down"></i>
          </Navbar.Toggle>

          <Navbar.Collapse>
            {/* Divider */}
            <hr className="d-lg-none w-100 my-2" />

            <Nav className="align-items-lg-center">
              <label className="nav-item navbar-text navbar-search-box p-0 active">
                <i className="ion ion-ios-search navbar-icon align-middle"></i>
                <span className="navbar-search-input pl-2">
                  <FormControl
                    className="navbar-text mx-2"
                    placeholder="Search..."
                    style={{ width: "200px" }}
                    values={this.state.searchTerm}
                    onChange={(e) => {
                      this.setState({ searchTerm: e.target.value });
                    }}
                    onKeyDown={(keyEvent) => {
                      if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                        window.location.href =
                          "/jobs?q=" + this.state.searchTerm;
                        keyEvent.preventDefault();
                      }
                    }}
                  />
                </span>
              </label>
            </Nav>
            <Nav className="align-items-lg-center ml-auto">
              <PartnerNavSelector claims={this.props.claims} />

              {(this.props.claims.default_role == "owneroperator" ||
                this.props.claims.default_role == "teamadmin") && (
                <Nav.Link
                  className="demo-navbar-notifications mr-lg-2"
                  onClick={() => this.props.showModal("QuickAdd")}
                >
                  <i className="ion ion-md-add-circle navbar-icon align-middle"></i>
                  <span className="d-lg-none align-middle">
                    &nbsp; Quick Add
                  </span>
                </Nav.Link>
              )}
              {/* Divider */}
              <div className="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-50 mr-3 ml-1">
                |
              </div>
              <Dropdown
                as={Nav.Item}
                className="demo-navbar-user"
                alignRight={!this.isRTL}
              >
                <Dropdown.Toggle as={Nav.Link}>
                  <span className="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                    <img
                      src={`${process.env.PUBLIC_URL}/img/defaultavatar.jpg`}
                      className="d-block ui-w-30 rounded-circle"
                      alt="User"
                    />
                    <span className="px-1 mr-lg-2 ml-2 ml-lg-0">
                      {this.props.claims ? this.props.claims.email : ""}
                    </span>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {(this.props.claims.default_role === "superadmin" ||
                    this.props.claims.default_role == "owneroperator" ||
                    this.props.claims.default_role === "teamadmin" ||
                    this.props.claims.default_role === "user") && (
                    <LinkContainer to={`/admin/emailtemplates`}>
                      <Dropdown.Item>
                        <i
                          className="ion ion-ios-mail-open"
                          style={{ width: "15px" }}
                        ></i>{" "}
                        &nbsp; My Email Templates
                      </Dropdown.Item>
                    </LinkContainer>
                  )}

                  {(this.props.claims.default_role === "superadmin" ||
                    this.props.claims.default_role == "owneroperator" ||
                    this.props.claims.default_role === "teamadmin") && (
                    <>
                      <LinkContainer
                        to={`/admin/users/${this.props.claims.user_id}`}
                      >
                        <Dropdown.Item>
                          <i
                            className="ion ion-ios-person"
                            style={{ width: "15px" }}
                          ></i>{" "}
                          &nbsp; My Profile
                        </Dropdown.Item>
                      </LinkContainer>

                      <LinkContainer to={`/partner`}>
                        <Dropdown.Item>
                          <i
                            className="fas fa-building"
                            style={{ width: "15px" }}
                          ></i>{" "}
                          &nbsp; My Company
                        </Dropdown.Item>
                      </LinkContainer>

                      <Dropdown.Item
                        onClick={() =>
                          this.setState({ showSubscriptionModal: true })
                        }
                      >
                        <i
                          className="fas fa-recycle"
                          style={{ width: "15px" }}
                        ></i>{" "}
                        &nbsp; Change Subscription
                      </Dropdown.Item>
                      <LinkContainer to={`/quickbooks-connect-disconnect`}>
                        <Dropdown.Item>
                          <i
                            className="fas fa-link"
                            style={{ width: "15px" }}
                          ></i>{" "}
                          &nbsp; Connect To Quickbooks
                        </Dropdown.Item>
                      </LinkContainer>

                      <Dropdown.Divider />
                    </>
                  )}

                  <Dropdown.Item onClick={this.signOut}>
                    <i
                      className="fas fa-sign-out-alt text-danger"
                      style={{ width: "15px" }}
                    ></i>{" "}
                    &nbsp; Log Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {this.state.showSubscriptionModal && (
          <UpgradeSubscriptionModal
            modalOpen={this.state.showSubscriptionModal}
            setModalOpen={(s) => this.setState({ showSubscriptionModal: s })}
            claims={this.props.claims}
          />
        )}
      </>
    );
  }
}

LayoutNavbar.propTypes = {
  sidenavToggle: PropTypes.bool,
};

LayoutNavbar.defaultProps = {
  sidenavToggle: true,
};

export default connect((store) => ({
  navbarBg: store.theme.navbarBg,
}))(LayoutNavbar);
